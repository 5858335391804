
<template>
  <div class="home">
    <Header></Header>
  <div style="margin:0px auto;max-width:640px;width:100%;overflow: hidden;">
<!--    <div style="width:100%;position: fixed;left: 0;top: 0;z-index: 999">-->

<!--      <a href="#" style="display: block;width: 7%;height: 100%;top: 0;left: 21%;position: absolute;"></a>-->
<!--      <a href="#"  @click="goNewMessage" style="display: block;width: 14%;height: 100%;top: 0;left: 33%;position: absolute;"></a>-->
<!--      <a href="#" @click="goFeedback" style="display: block;width: 15%;height: 100%;top: 0;left: 51%;position: absolute;"></a>-->
<!--      <a href="#" @click="goSkills" target="_blank" style="display: block;width: 17%;height: 100%;top: 0;left: 69%;position: absolute;"></a>-->
<!--      <a href="https://club.lenovo.com.cn/forum-1349-1.html" target="_blank" style="display: block;width: 8%;height: 100%;top: 0;left: 90%;position: absolute;"></a>-->
<!--      &lt;!&ndash; <a href="https://activity.lenovo.com.cn/xiaofei/pingban/xxpb.index.html" target="_blank" style="display: block; width:6%; height: 100%; top: 0;left: 77%; position: absolute;"></a>-->
<!--&ndash;&gt;-->

<!--      <img src="../assets/images/1.jpg" style="border:0px;display:block;width:100%;" />-->
<!--    </div>-->
<!--    <div style="opacity: 0;width: 100%">-->
<!--      <img src="../assets/images/1.jpg" style="border:0px;display:block;width:100%;" />-->
<!--    </div>-->
    <div style="width:100%;"><img src="../assets/images/2.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/3.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/4.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/5.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/6.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/7.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/8.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/9.png" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/10.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/11.png" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/12.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/13.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/14.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/15.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/16.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;"><img src="../assets/images/18.jpg" style="border:0px;display:block;width:100%;" /></div>
    <div style="width:100%;position: relative;">
      <!-- 【底部导航】
      新浪微博：https://weibo.com/izuk
      官方微信：稍后更新
      云服务：https://www.zui.com/cloudservice
      解锁：https://www.zui.com/iunlock
      开发者计划：https://cdnzui.lenovomm.cn/web/book/Lenovo_Tablet_Document.zip -->
      <a href="https://weibo.com/izuk" target="_blank" style="display: block; width: 21%; height: 100%; top: 0;left:0%; position: absolute;"></a>
      <!-- 微信链接-->
      <a   onclick="document.querySelector('.mask').style.display='block'"   style="display: block; width: 21%; height: 100%; top: 0;left: 18%; position: absolute;">

      </a>
      <a href="https://www.zui.com/cloudservice" target="_blank" style="display: block; width:21%; height: 100%; top: 0;left: 40%; position: absolute;"></a>
      <a href="https://www.zui.com/iunlock" target="_blank" style="display: block; width:21%; height: 100%; top: 0;left: 60%; position: absolute;"></a>
      <a href="https://cdnzui.lenovomm.cn/web/book/Lenovo_Tablet_Document.zip " target="_blank" style="display: block; width:21%; height: 100%; top: 0;left: 80%; position: absolute;"></a>
      <img src="../assets/images/19.jpg" style="border:0px;display:block;width:100%;" />

    </div>
    <div style="width: 100%; position: relative;">
      <a  href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.115.75c446f3OnNiw6#/Integrated/index" target="_blank" style="display: block; width: 39%; height: 27%; top: 46%; left: 32%; position: absolute;"></a>
      <a  href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="display: block; width: 49%; height: 17%; top: 70%; left: 43%; position: absolute;"></a>
      <img src="../assets/images/20.jpg" style="border: 0px; display: block; width: 100%;">
    </div>
    <div class="mask"   onclick="document.querySelector('.mask').style.display='none'"  style="position: fixed;left: 0;top: 0;bottom: 0;right: 0;background-color: rgba(0,0,0,0.9);display: none">
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
        <img src="../assets/images/erwei-code.png" style="width: 50%;">
      </div>

    </div>
  </div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
  name: 'index',
  components: {Header},
  data() {
    return {

    }
  },
  methods: {
    goNewMessage() {
      this.$router.push({
        name: 'newMessage',
      })
    },
    goFeedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
    goSkills() {
      this.$router.push({
        name: 'skillsMain',
      })
    },
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
</style>
